/* ============================================================
   =                    Privacy Policy Header                  =
   ============================================================ */

   .header-policy {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; /* Ensures the header stays on top */
    background: #232323; /* Dark background for header */
    padding: 40px; /* Spacious padding for header */
}

/* ============================================================
   =                  Privacy Policy Container                 =
   ============================================================ */

.policy-container {
    color: #ccc; /* Light gray text for readability */
    background: #232323; /* Matches header background */
    padding: 20px;
    margin-bottom: 100px; /* Prevents content overlap at the bottom */
}

/* Heading styles */
.policy-container h1 {
    padding-top: 100px; /* Adds spacing from top header */
    font-size: 36px; /* Large heading */
    text-align: center;
    margin-top: 0;
    color: white; /* White heading text */
}

.policy-container h2 {
    font-size: 28px; /* Slightly smaller heading */
    color: #ffffff; /* White color for sub-headings */
    border-bottom: 2px solid #ccc; /* Underline for emphasis */
    margin-bottom: 20px;
    padding-bottom: 10px; /* Padding under heading for spacing */
    
}

/* ============================================================
   =                    Text and List Styling                  =
   ============================================================ */

/* General paragraph styling */
.policy-container p {
    font-size: 18px; /* Standard text size */
    line-height: 1.6; /* Good readability with proper line spacing */
    margin-bottom: 20px;
}

/* Ordered list styling */
.policy-container ol {
    line-height: 1.6; /* Consistent line spacing */
    font-size: 18px;
}

/* List item styling */
.policy-container li {
    line-height: 1.6;
    font-size: 18px;
}

/* ============================================================
   =                      Link Styling                         =
   ============================================================ */

.policy-container a {
    color: #ff4d4d; /* Bright red for emphasis */
    text-decoration: none; /* Removes underline by default */
}

.policy-container a:hover {
    text-decoration: underline; /* Underline on hover for better interaction */
}
