/* 
Stylesheet for Login.js, SignUp.js, and ForgetPassword.js components. 
This file contains styles for form layouts, input fields, buttons, 
and responsive design for authentication-related pages. 
*/

/* Existing desktop styles */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
  overflow-x: hidden;
  padding-bottom: 200px;
}

.container,.container-s,.forgot-container {
  display: flex;
  justify-content: center; /* Center the container content */
  align-items: center;
  width: 90%;
  max-width: 1200px;
  background-color: transparent;
  min-height: 100vh; /* Ensure it takes full viewport height */
  margin: 18px auto; /* Center the container horizontally */
  flex-direction: row; /* Default to row for larger screens */
}

.logo-image,.logo-image-s,.logo-image-f {
  height: 120px;
  /* Remove margin-left and margin-bottom for better flexibility */
}

.login-box,.signup-box,.forgot-box {
  width: 400px;
  padding: 20px;
  border-radius: 10px;
  margin: 0 auto;
}

.login-text,.signup-text,.forgot-text   {
  font-size: 48px;
  color: white;
  text-align: center;
  margin-bottom: 25px;
}

.input-group {
  margin-bottom: 20px;
}

.input-group input {
  width: 100%;
  padding: 15px;
  border: 1px solid rgba(189, 189, 189, 0.75);
  border-radius: 30px;
  font-size: 16px;
  background: #333;
  color: #ccc;
  outline: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  /* Fixed height */
  height: 20px;
}

.input-group input:focus {
  border: 2px solid rgba(255, 0, 0, 0.75);
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
}
/* For the input wrapper */
.password-input-wrapper {
  position: relative;
  width: 100%;
}
/* For the toggle password icon */
.toggle-password-L {
  position: absolute;
  top: 50%;
  right: 0px; /* Adjust as needed to keep it inside the input */
  transform: translateY(-50%);
  cursor: pointer;
  color:white; /* Adjust icon color */
}

.toggle-password-L.open {
  color: #ff0000; /* Color when the eye is open */
}


.remember-me {
  text-align: center;
  color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0 auto;
  padding-bottom: 20px;
}

.remember-me input[type="checkbox"] {
  transform: scale(1.5);
  margin-right: 5px;
  accent-color: red;
}

.login-button,
.google-login-button,
.google-login-button-s,
.signup-button,
.forgot-button {
  width: 107%; 
  max-width: 500px; 
  padding: 15px;
  border: none;
  border-radius: 40px;
  font-size: 16px;
  cursor: pointer;
  margin: 10px auto; /* Center the buttons */
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  height: 40px; /* Fixed height */
  font-size: 18px;
  /* Center text and adjust button content */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
}

.login-button,
.signup-button,
.forgot-button  {
  background-color: #ff0000;
  color: white;
}

.login-button:hover,
.signup-button:hover,
.forgot-button:hover {
  background-color: #cc0000;
}

.google-login-button,
.google-login-button-s {
  background-color: white;
  color: #555;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 7px;
}

.google-login-button .google-icon,
.google-login-button-s .google-icon {
  font-size: 18px;
}

.google-login-button:hover {
  background-color: #f0f0f0;
}

.forgot-password {
  text-align: center;
  margin-bottom: 20px;
}

.forgot-password a {
  color: #ccc;
  text-decoration: none;
}

.signup-link,
.login-link  {
  text-align: center;
  color: #ccc;
}

.signup-link a,
.login-link a  {
  color: #ff0000;
  text-decoration: none;
}

.signup-link a:hover,
.forgot-password a:hover,
.login-link a:hover {
  text-decoration: underline;
}

.error {
  color: red;
  text-align: center;
}
.error-message {
  color: red;
  text-align: center;
}

.success-message {
  color: green;
  text-align: center;
}


.black-image {
  position: absolute;
  top: 10.34px;
  left: 250px;
  width: 137.39px;
  height: 137.39px;
}

.Red-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 230.72px;
  height: 230.72px;
  object-fit: cover;
  clip-path: polygon(
    0 0,
    254.72px 0,
    254.72px 254.72px,
    0 254.72px
  );
}

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid transparent;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: 40px;
  max-width: 500px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
  margin: 0;
  padding: 9px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state, 
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}

.google-login-button,
.google-login-button-s {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 30px;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  height: 40px; /* Adjusted height */
  line-height: 50px; /* Align text vertically */
  display: flex;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  max-width: 500px; /* Fixed maximum width */
  margin: 10px auto; /* Center the button */
font-size:16px;
}

.google-login-button:hover,
.google-login-button-s:hover {
  background-color: #f1f1f1;
  border-color: #bbb;
  border-radius: 20px;
}

.gsi-material-button-content-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.gsi-material-button-icon svg {
  width: 20px; /* Adjust icon size */
  height: 20px; /* Adjust icon size */
  margin-right: 8px; /* Space between icon and text */
}

/* Responsive Styles */

/* Tablets and Mobile Devices (max-width: 768px) */
@media (max-width:1024px){
  .black-image,
  .Red-image {
    display: none; 
  }
}
@media (max-width: 768px) {
  .container,
.container-s
 {
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    margin-left: 0px; /* Remove side margins */
    padding: 20px; /* Add some padding */
  }

  .forgot-container{
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    margin-left: 0px; /* Remove side margins */
    padding: 25px; /* Add some padding */
  }
  

  .logo-image,
  .logo-image-s,
  .logo-image-f {
    height: 80px; /* Adjust logo size */
    margin-left: 100px; /* Center the logo */
  }
  .login-box,.signup-box,
  .forgot-box {
    width: 70%; /* Make it responsive */
    padding: 15px;
    margin: 0 auto; /* Center the login box */
  }

  .login-text,.signup-text,
  .forgot-text {
    font-size: 45px;
    margin-left:0px;
    margin-top:0px;
  }

  .input-group input {
    padding: 12px;
    font-size: 16px;
    height: 25px; /* Adjust height */
  }

  .login-button,
  .google-login-button,
  .google-login-button-s,
  .signup-button,
  .forgot-button 
  
  {
    width: 105%; /* Full width */
    max-width: 510px; /* Fixed max width */
    padding: 12px;
    height: 40px; /* Fixed height */
   margin:10px auto; /* Center the buttons */
  font-size: 15px;
  }

  .remember-me {
    margin: 0 auto;
    padding-bottom: 20px;
  }

  /* Remove specific margin adjustments for smaller screens */
  .black-image,
  .Red-image {
    display: none; /* Hide decorative images on smaller screens if necessary */
  }
}

/* Additional Media Queries for Specific Smaller Screens */

/* Landscape Phones and Small Tablets (max-width: 480px) */
@media (max-width: 480px) {
  .container,.container-s{
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    margin-left: 0px; /* Remove side margins */
    padding:0px; 
  }
 .forgot-container{
  margin-left:0px;
  padding:10px
 }
  .logo-image{
    height: 60px; 
  margin-left:150px;
  }
  .logo-image-s{
    margin-left:50px;
    height: 60px;
  }
  .logo-image-f{
    height: 60px;
    margin-left:0px;
  }

  .login-text,.signup-text {
    font-size: 33px; 
  }

  .input-group input {
    padding: 10px;
    height: 15px; /* Further adjust height */
  }
  .login-button,
  .google-login-button,
  .google-login-button-s,
  .signup-button,
  .forgot-button
  {
    width: 108%; /* Full width */
    max-width: 510px; /* Fixed max width */
    
    height: 35px; /* Fixed height */
    margin: 10px auto; /* Center the buttons */
  }

  .signup-link, .login-link {
    text-align: center; 
    margin-top: -5px; 
    display: flex;
    flex-direction: column; 
    align-items: center; 
}

.signup-link Link, .login-link Link {
    display: inline-block; 
    font-weight: bold; 
}

}
@media (max-width: 376px)
{
  .logo-image{
    margin-left:120px;
  }
}
@media (max-width: 321px)
{
 .container,
 .container-s
 {
  margin-left:-6px;
 }
 .forgot-container{
  padding:0px;
  margin-left:4px;
 }
 .forgot-password{
  font-size: 16px;
  margin-top:20px;
 }

 .login-button,
  .google-login-button,
  .google-login-button-s,
  .signup-button,
  .forgot-button
  
   {
    width: 110%; /* Full width */
    max-width: 510px; /* Fixed max width */
    padding: 10px;
    height: 33px; /* Fixed height */
    margin: 10px auto; /* Center the buttons */
  }
  
  .logo-image
  {
    margin-left:100px;
  }
  .signup-link, .login-link {
    text-align: center; 
    margin-top: 20px; 
    display: flex;
    flex-direction: column; 
    align-items: center; 
    
}
.forgot-password{
  margin-top:8px;
  margin-bottom: -7px;
}
.signup-link Link, .login-link Link {
    display: inline-block; 
    font-weight: bold; 
    
}
}