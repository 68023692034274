/* 
Stylesheet for About.js, Contact.js, and Recommendations.js components.
This file contains styles for the main layout, typography, buttons, tooltips, 
and responsive design for these pages. 
*/
/* Global Styles */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #232323;
  color: white;
  overflow-x: hidden;
  margin-bottom: 60px; /* Adds spacing at the bottom of the body */
}

/* Section for main content */
.content-section {
  background: transparent; 
  padding: 0; 
  border-radius: 10px;
  box-shadow: none; 
  text-align: center; 
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  margin: 0;
}

/* Heading styles inside content section */
.content-section h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 40px; 
  margin-top: 250px;
  margin-bottom: 10px; 
  color: white;
}

/* Paragraph styles inside content section */
.content-section p {
  font-family: 'Poppins', sans-serif;
  font-size: 36px; 
  line-height: 1.2;
  margin-top: 10px; 
  margin-bottom: 10px;
  word-spacing: 10px; /* Adds spacing between words */
  color: white;
}

/* Styling for unordered list inside content section */
.content-section ul {
  list-style-type: none; /* Removes default bullets from list */
  padding: 0; 
  margin: 0; 
  text-align: left; 
  margin-right: 550px; /* Adds right margin to align the list */
}

/* Styling for each list item inside unordered list */
.content-section ul li {
  position: relative; 
  padding-left: 40px; /* Creates space for custom bullet */
  margin-bottom: 1rem; 
  font-family: 'Poppins', sans-serif;
  font-size: 24px; 
  line-height: 1.4;
  color: white;
}

/* Custom bullet for list items */
.content-section ul li::before {
  content: ''; 
  position: absolute; 
  left: 0; 
  top: 50%; 
  transform: translateY(-50%); /* Vertically centers the bullet */
  width: 15px; 
  height: 15px; 
  background-color: #ff4d4d; /* Red bullet color */
  border-radius: 50%; /* Makes the bullet circular */
}

/* Styling for install button */
.install-button {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%; /* Makes the button circular */
  background-color: #232323;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition-duration: .3s; /* Smooth hover transition */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.11);
}

/* Icon styling inside install button */
.svgIcon {
  fill: white; /* Sets icon color */
}

/* Custom icon styling */
.icon2 {
  width: 18px;
  height: 5px;
  border-bottom: 2px solid white;
  border-left: 2px solid white;
  border-right: 2px solid white;
}

/* Tooltip styling for button */
.tooltip {
  position: absolute;
  right: -105px;
  opacity: 0;
  background-color: rgb(12, 12, 12);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: .2s; /* Smooth transition for tooltip appearance */
  pointer-events: none; /* Disables mouse events on tooltip */
  letter-spacing: 0.5px;
}

/* Tooltip arrow */
.tooltip::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background-color: rgb(12, 12, 12);
  background-size: 1000%;
  background-position: center;
  transform: rotate(45deg); /* Rotates square to create arrow effect */
  left: -5%;
  transition-duration: .3s;
}

/* Hover effect for button tooltip */
.install-button:hover .tooltip {
  opacity: 1; /* Makes the tooltip visible */
  transition-duration: .3s;
}

/* Hover effect for install button */
.install-button:hover {
  background-color: #ff4d4d; /* Changes button background color on hover */
  transition-duration: .3s;
}

/* Hover effect for icon inside button */
.install-button:hover .icon2 {
  border-bottom: 2px solid rgb(235, 235, 235); /* Changes border color */
  border-left: 2px solid rgb(235, 235, 235);
  border-right: 2px solid rgb(235, 235, 235);
}

/* Hover effect for SVG icon inside button */
.install-button:hover .svgIcon {
  fill: rgb(255, 255, 255); /* Changes icon color */
  animation: slide-in-top 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* Slide-in animation */
@keyframes slide-in-top {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

/* Red triangular shapes for decorative purposes */
.Red-bottom, .Red-Left {
  position: fixed; 
  width: 250px; 
  height: 250px; 
  object-fit: cover; 
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); /* Creates a triangle */
}

.Red-bottom {
  bottom: 0; 
  right: 0; /* Positioned at the bottom-right corner */
}

.Red-Left {
  left: -130px; 
  top: 130px; /* Positioned on the left side */
}

/* Responsive Styles */

/* Medium screens (Tablets and smaller laptops) */
@media (max-width: 1024px) {
  .content-section h2 { font-size: 36px; margin-top: 200px; }
  .content-section p { font-size: 30px; }
  .content-section ul { margin-right: 400px; }
  .content-section ul li { font-size: 20px; }
  .Red-bottom, .Red-Left { width: 200px; height: 200px; } /* Smaller red shapes */
}

/* Small screens (Tablets in portrait mode) */
@media (max-width: 768px) {
  .content-section h2 { font-size: 30px; margin-top: 220px; }
  .content-section p { font-size: 26px; }
  .content-section ul { margin-right: 250px; }
  .content-section ul li { font-size: 18px; }
  .install-button { width: 40px; height: 40px; } /* Smaller button size */
  .Red-bottom, .Red-Left { width: 180px; height: 180px; }
}

/* Extra small screens (Mobile devices) */
@media (max-width: 426px) {
  .content-section h2 { font-size: 24px; margin-top: 180px; }
  .content-section p { font-size: 18px; padding: 0 20px; word-spacing: 5px; }
  .content-section ul { margin-right: 0px; }
  .content-section ul li { font-size: 16px; }
  .install-button { width: 30px; height: 30px; } /* Further reduced button size */
  .Red-bottom, .Red-Left { display: none; } /* Hide red shapes on small screens */
}
