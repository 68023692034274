/* ============================================================
   =                       Header Styles                       =
   ============================================================ */

   .header {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1100; /* Ensures the header is always above other elements */
  }
  
  .profile-pic-container-h {
    position: fixed;
    top: 15px;
    right: -60px;
    z-index: 1100;
    margin-right: 120px; /* Adjusts spacing for profile picture container */
  }
  
  .container-header {
    position: fixed;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center; /* Vertically centers the logo */
    color: inherit;
    text-decoration: none;
  }
  
  .logo-image-h {
    height: 58px;
    width: auto; /* Maintains aspect ratio */
  }
  
  /* ============================================================
     =                   Icon and Dropdown Menu                  =
     ============================================================ */
  
  .list-icon {
    font-size: 1.5rem; /* Scalable icon size */
    cursor: pointer;
    color: white;
    margin: 3px 0 0 0; /* Adjusts alignment */
  }
  
  .dropdown {
    position: absolute;
    top: 20px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Adds shadow for depth */
    max-width: 200px;
    margin-top: 10px;
    box-sizing: border-box;
    height: auto; /* Flexible height */
    overflow-y: hidden; /* Hides content overflow */
  }
  
  .dropdown ul {
    list-style: none; /* Removes default list styles */
    padding: 0;
    margin: 0;
  }
  
  .dropdown li {
    margin: 10px 0; /* Adds space between list items */
  }
  
  .dropdown a {
    color: white;
    text-decoration: none;
    display: block;
    padding: 5px 10px; /* Padding for links */
    font-size: 18px;
  }
  
  .dropdown a:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Subtle hover effect */
  }
  
  .dropdown button {
    color: red;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    width: 100%; /* Full-width buttons */
    margin-top: 10px;
    font-size: 18px;
  }
  
  .dropdown button:hover {
    background-color: rgba(255, 0, 0, 0.1); /* Hover effect for buttons */
  }
  
  /* ============================================================
     =                      Responsive Styles                    =
     ============================================================ */
  
  /* Tablets and smaller screens (Max width: 1025px) */
  @media (max-width: 1025px) {
    .dropdown {
      width: 180px;
      padding: 10px; /* Adjusts padding for smaller screens */
    }
  
    .dropdown a {
      font-size: 16px; /* Scales down font size */
    }
  
    .dropdown button {
      font-size: 16px;
    }
  }
  
  /* Mobile screens (Max width: 480px) */
  @media (max-width: 480px) {
    .dropdown {
      top: 20px;
      width: 160px;
      max-height: 270px;
      padding: 10px;
    }
  
    .dropdown a {
      padding: 6px 4px;
      font-size: 16px;
    }
  
    .dropdown button {
      padding: 2px 2px;
      font-size: 16px;
    }
  }
  