/* ============================================================
   =                   Stylesheet for VideoPage.js             =
   =       This stylesheet is responsible for the layout,      =
   =       responsiveness, and styling of the video page.      =
   ============================================================ */
  /* ============================================================
     =                 Video Page Container Styles               =
     ============================================================ */
  
  .video-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(145deg, #2c2c2c, #1a1a1a);
    position: relative;
    margin: 0;
    padding: 0;
  }
  
  /* ============================================================
     =                      Menu Icon Styles                     =
     ============================================================ */
  
  .menu-icon {
    font-size: 35px;
    cursor: pointer;
    color: white;
    position: fixed;
    top: 18px;
    right: 20px;
    z-index: 1002; /* Ensures it stays above other elements */
    margin-bottom: 648px; /* Adjust as needed */
  }
  
  /* ============================================================
     =                   Dropdown Menu Styles                    =
     ============================================================ */
  
  .dropdown-menu {
    position: fixed;
    top: 57px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    max-width: 200px;
    box-sizing: border-box;
    z-index: 1003; /* Above menu icon */
  }
  
  .dropdown-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-menu li {
    margin: 10px 0;
  }
  
  .dropdown-menu a {
    color: white;
    text-decoration: none;
    display: block;
    padding: 5px 10px;
    font-size: 18px;
    transition: background-color 0.3s ease;
  }
  
  .dropdown-menu a:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .dropdown-menu button {
    color: red;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    width: 100%;
    margin-top: 10px;
    font-size: 18px;
    transition: background-color 0.3s ease;
  }
  
  .dropdown-menu button:hover {
    background-color: rgba(255, 0, 0, 0.1);
  }
  
  /* ============================================================
     =                      Video Frame Styles                   =
     ============================================================ */
  
  .video-frame {
    width: 1000px;
    height: 562px;
    border: none;
    z-index: 1;
  }
  
  /* ============================================================
     =                      Back Button Styles                   =
     ============================================================ */
  
  .back-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1001; /* Below dropdown menu */
    font-size: 66px;
  }
  
  .back-button img {
    width: 55px;
    height: 55px;
  }
  
  /* ============================================================
     =                    Watch Images Styles                     =
     ============================================================ */
  
  .red-watch-image {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 170px;
    height: auto;
  }
  
  .black-watch-image {
    position: absolute;
    top: 40px;
    left: 1068px;
    width: 300px;
    height: 300px;
    z-index: 0;
    object-fit: cover;
  }
  
  /* ============================================================
     =                        Responsive Styles                  =
     ============================================================ */
  
  /* ----- Tablet View (Max Width: 1025px) ----- */
  @media (max-width: 1025px) {
    .video-frame {
      width: 800px;
      height: 450px;
    }
  
    .menu-icon {
      font-size: 30px;
    }
  
    .red-watch-image,
    .black-watch-image {
      display: none;
    }
  
    .dropdown-menu {
      top: 50px;
      right: 20px;
      width: 180px;
      padding: 10px;
    }
  
    .dropdown-menu a,
    .dropdown-menu button {
      font-size: 16px;
    }
  }
  
  /* ----- Mobile Landscape/Tablets (Max Width: 769px) ----- */
  @media (max-width: 769px) {
    .video-frame {
      width: 600px;
      height: 337px;
    }
  
    .menu-icon {
      font-size: 25px;
    }
  
    .red-watch-image,
    .black-watch-image {
      display: none;
    }
  
    .back-button {
      top: 15px;
      font-size: 35px;
    }
  
    .back-button img {
      width: 40px;
      height: 40px;
    }
  
    .dropdown-menu {
      top:47px;
    }
  }
  
  /* ----- Mobile Portrait (Max Width: 480px) ----- */
  @media (max-width: 480px) {
    .video-frame {
      width: 90%;
      height: 40%;
      padding: 10px;
    }
  
    .menu-icon {
      font-size: 18px;
    }
  
    .red-watch-image,
    .black-watch-image {
      display: none;
    }
  
    .back-button img {
      width: 30px;
      height: 30px;
    }
  
    .dropdown-menu {
      top: 38px;
      right: 19px;
      width: 160px;
      max-height: 270px;
      padding: 10px;
    }
  
    .dropdown-menu a,
    .dropdown-menu button {
      font-size: 16px;
      padding: 6px 4px;
    }
  }
  
  /* ----- Extra Small Devices (Max Width: 320px) ----- */
  @media (max-width: 320px) {
    .video-frame {
      width: 95%;
      height: 30%;
    }
  }
  