/* src/scrollbar.css */

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
}

* {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.scroll-arrow {
    position: absolute;
    top: 38%; 
    transform: translateY(-50%);
    width: 70px;  
    height: 70px; 
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    color: white;
    font-size: 15px;  
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;  
    z-index: 10;
    transition: background-color 0.3s;
   
}

.scroll-arrow.right {
    right: 10px;
}

.scroll-arrow:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.scroll-arrow:focus {
    outline: none;
}

.scroll-arrow:active {
    background-color: rgba(0, 0, 0, 0.9);
}


.categories-container {
    padding: 20px;
    overflow-x: hidden;
}

.video-category-container {
    position: relative;
}

.video-category {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
}

.video-category-title {
    margin-bottom: 10px;
}
.no-videos-message
,.coming-soon-message
,.default-message{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    
    font-family: 'Raleway', sans-serif;
}

.no-videos-message h2,
.coming-soon-message h2,
.default-message h2{
    font-size: 42px;
    margin-bottom: 10px;
}

.no-videos-message p 
,.coming-soon-message p,
.default-message p{
    font-size: 35px;
    margin: 5px 0;
}


/* Existing CSS */

@media (max-width: 768px) {
    .scroll-arrow {
        display: none !important;
    }
    .no-videos-message,
    .coming-soon-message{
        margin-left:-20px;
    }
    .no-videos-message h2
    ,.coming-soon-message h2{
        font-size: 42px;
        margin-right:30px;
    }
    .no-videos-message p,
    .coming-soon-message p{
        font-size: 35px;
        margin-right:25px;
    }
}

@media (max-width: 480px) {
    .coming-soon-message{
        margin-left:30px
    }
    .no-videos-message,
    .coming-soon-message,
    .default-message{
margin-right: 8px;
    }
    .no-videos-message h2,
    .coming-soon-message h2,
    .default-message h2 {
        font-size: 30px;
        
    }
    
    .no-videos-message p ,
    .coming-soon-message p,
    .default-message p{
        font-size: 25px; 
       
    }

    .video-category-title {
        font-size: 18px; 
    }
    .video-title{
        font-size:15px;
    }
}
