/* ============================================================
   =                        Footer Styles                      =
   ============================================================ */

   .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #232323; /* Dark footer background */
    color: white;
    text-align: center;
    padding: 10px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0); /* Subtle shadow */
    display: flex; /* Flexbox to center content */
    justify-content: center; /* Horizontal centering */
    align-items: center; /* Vertical centering */
  }
  
  .footer p {
    margin: 0;
    font-size: 14px; /* Standard footer text size */
  }
  
  .footer-link {
    color: white; /* Link color */
    text-decoration: none; /* No underline by default */
    font-weight: bold; /* Optional: bold text for links */
  }
  
  .footer-link:hover {
    text-decoration: underline; /* Underline on hover for better interaction */
  }
  
  /* ============================================================
     =                    Responsive Adjustments                 =
     ============================================================ */
  
  /* Mobile screens (Max width: 480px) */
  @media (max-width: 480px) {
    .footer {
      padding: 10px 5px; /* Adjust padding for smaller devices */
    }
  
    .footer p {
      font-size: 11px; /* Smaller text on mobile screens */
    }
  
    .footer-link {
      font-size: 11px; /* Smaller link font size for mobile */
    }
  }
  