/* src/styles/Main.css */
.main-body {
    margin: 0;
    background: #232323;
    
}

.header-main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: #232323;
    padding: 40px;

}

.main-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #232323;
    overflow-y: auto;
    margin-bottom: 100px;
    margin-top:100px;
}

#videoInput {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 10px;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 0 20px;
    margin-top: 10px;
    margin-left:140px;
}

#videoInput input {
    width: 500px;
    padding: 0;
    border: 1px solid rgba(189, 189, 189, 0.75);
    border-radius: 30px;
    font-size: 25px;
    font-family: 'Bebas Neue', sans-serif;
    background: #333;
    color: #ccc;
    outline: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    text-align: center;
    height: 40px;
}

#videoInput button {
    width: 120px;
    padding: 6.5px;
    border: 2px solid rgb(50, 50, 50);
    border-bottom: 5px solid rgb(50, 50, 50);
    border-radius: 30px;
    background-image: linear-gradient(rgb(255, 77, 77), rgb(224, 67, 67));
    box-shadow: 0px 1px 6px 0px rgb(224, 67, 67);
    font-size: 17px;
    font-family: 'Bebas Neue', sans-serif;
    color: white;
    cursor: pointer;
    text-align: center;
    height: 40px;
    margin-left: 20px;
}

#videoInput button:hover {
    background-image: linear-gradient(rgb(224, 67, 67), rgb(204, 47, 47));
}

#videoInput button:active {
    border-bottom: 2px solid rgb(50, 50, 50);
}

.video-category {
    display: flex;
    flex-direction: row;
    padding: 20px 0;
    gap: 10px;
    max-width: 100%;
    margin-top: 20px;
    padding: 0 20px;
    overflow-y: hidden;
}

.video-category h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 0px;
   
}

.video {
    flex: 0 0 auto;
    width: 200px;
    margin-right: 10px;
    transition: transform 0.2s, box-shadow 0.2s;
}

.video:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

iframe {
    width: 100%;
    height: 115px;
    border: none;
    border-radius: 10px;
}
.delete-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(255, 69, 69);
    border: none;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 20px red;
    cursor: pointer;
    transition-duration: 0.3s;
    overflow: hidden;
    position: relative;
    gap: 1px;
  }
  
  .delete-button .svgIcon {
    width: 12px;
    transition-duration: 0.3s;
  }
  
  .delete-button .svgIcon path {
    fill: white;
  }
  
  .delete-button:hover {
    width: 140px;
    border-radius: 50px;
    transition-duration: 0.3s;
    background-color: #FF0000;
    align-items: center;
    gap: 0;
  }
  
  .delete-button:hover .bin-bottom {
    width: 50px;
    transition-duration: 0.3s;
    transform: translateY(60%);
  }
  
  .bin-top {
    transform-origin: bottom right;
  }
  
  .delete-button:hover .bin-top {
    width: 50px;
    transition-duration: 0.3s;
    transform: translateY(60%) rotate(160deg);
  }
  
  .delete-button::before {
    position: absolute;
    top: -20px;
    content: "Delete";
    color: white;
    transition-duration: 0.3s;
    font-size: 2px;
  }
  
  .delete-button:hover::before {
    font-size: 13px;
    opacity: 1;
    transform: translateY(35px);
    transition-duration: 0.3s;
  }
.video-thumbnail {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}

.logo-container ul {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.logo-container li {
    list-style: none;
    display: inline-block;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.6s;
    transition-delay: 0.6s;
    background: transparent;
}

.youtube {
    border: 2px solid #ff0000;
    color: #ff0000;
}

.netflix {
    border: 2px solid #e50914;
    color: #e50914;
}

.tod {
    border: 2px solid yellow;
    color: yellow;
}

.disney {
    border: 2px solid #113ccf;
    color: #113ccf;
}

.shahid {
    border: 2px solid #1c7430;
    color: #1c7430;
}

.logo-button img {
    width: 180px;  
}
#youtube{
    width:380px;
}
#netflix{
    width:130px;
}
button.logo-button{
    background-color: transparent;
    border :none;
}
.youtube:hover {
    box-shadow: 0px 0px 7px #ff0000 inset,
                0px 0px 7px #ff0000;
}

.netflix:hover {
    box-shadow: 0px 0px 7px #e50914 inset,
                0px 0px 7px #e50914;
}

.tod:hover {
    box-shadow: 0px 0px 7px #00b140 inset,
                0px 0px 7px #00b140;
}

.disney:hover {
    box-shadow: 0px 0px 7px #113ccf inset,
                0px 0px 7px #113ccf;
}

.shahid:hover {
    box-shadow: 0px 0px 7px #1c7430 inset,
                0px 0px 7px #1c7430;
}


/* Add rotate-once class */
.rotate-once {
    transform: rotateY(360deg);
    transition: transform 0.6s ease;
  }
  

@media (max-width: 1200px) {
    #videoInput input {
        width: 300px;
        font-size: 20px;
    
    }

    #videoInput button {
        width: 100px;
        margin-left: 10px;
        font-size: 15px;
        padding: 6px;
        margin-right: 100px;
    }
    .logo-container li {
        
        height: 120px;
        width: 120px;
       
    }
    
    .logo-button img {
        transition: 0.6s;
        width: 110px;  
    }
    #youtube{
        width:250px;
    }
    #netflix{
        width:80px;
    }
    .logo-container {
        margin-top:5px;
       margin-right: 50px;
    }
    
    .logo-container ul {
        gap: 10px;
    }
    
   
}

@media (max-width: 768px) {
    .main-body{
        overflow: hidden;
    }
    .main-container{
        overflow: hidden;
    }
   #videoInput{
   margin-left:130px;
   }

    #videoInput input {
        width: 200px;
        height: 30px;
        font-size: 18px;
      
       
    }

    #videoInput button {
        width: 110px;
        height: 33px;
        font-size: 14px;
        padding: 5px;
        margin-left: 5px;
    margin-right:77px;
    }

    .categories-container {
        width: 100%;
    }
    .logo-container {
        
        margin-right: 50px;
        justify-content: center;
    }
   
}
@media (max-width: 480px) {
 
    #videoInput {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 72px;
        margin-left: 20px;
    }

    #videoInput input {
        width: 100%;
        height: 30px;
        font-size: 16px;
        margin-left: -12px;
        margin-bottom: 10px;
    }

    #videoInput button {
        width: 100%;
        height: 35px;
        font-size: 16px;
        padding: 5px;
        margin-left: -12px;
    }

    .main-container {
        margin-top: 118px; 
    }
    .header-main {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        background: #232323;
        padding: 85px;
    }
   
   
    
    .video {
        flex: 0 0 auto;
        width: 150px;
        margin-right: 10px;
        transition: transform 0.2s, box-shadow 0.2s;
        
    }
    
   .delete-button{
    width: 50px;
    
   }
   .logo-container {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between; 
    align-items: flex-start;
    margin-top: 50px;
    margin-right:auto;
}

.logo-container li {
    height: 90px;
    width: 90px;
    list-style-type: none;
}

.logo-container ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0;
    margin: 0;
}

.logo-button img {
    transition: 0.6s;
    width: 77px;  
}

#youtube {
    width: 170px;
}

#netflix {
    width: 70px;
}

}

