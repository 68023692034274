/* 
Stylesheet for ChangeEmail.js, ChangePassword.js, and Profile.js components. 
This file contains styles for layouts, typography, buttons, 
and responsive design for user account management pages. 
*/

/* ============================= */
/*        Containers             */
/* ============================= */

/* Parent container for centering */
.full-page-center {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Full height for centering */
    padding: 20px; /* Added padding for extra spacing */
    background-color: transparent; /* Background color managed by body now */
    box-sizing: border-box; /* Ensures padding is included in width/height calculation */
    flex-direction: column; /* Stack content vertically if necessary */
}

/* Containers for Email, Password, and Profile Forms */
.changeemail-container,
.change-password-container,
.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px; /* Adjusted max-width for profile-container */
    background-color: transparent;
    color: white;
    padding: 20px;
    box-sizing: border-box;
    margin: 0 auto; /* Ensures containers are centered horizontally */
}

.profile-container {
    max-width: 1200px;
    height: auto;
    margin: 70px auto 100px auto; /* Center container horizontally */
}

/* ============================= */
/*        Headers                */
/* ============================= */

/* Header Styles for Change Email, Change Password, and Profile */
.changeemail-container h2,
.change-password-container h2,
.profile-container h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
    color: white;
    text-align: center; /* Center text horizontally */
}

/* Responsive Header Adjustments */
@media (max-width: 1024px) {
    .changeemail-container h2,
    .change-password-container h2,
    .profile-container h2 {
        font-size: 40px;
    }
    
    
}

@media (max-width: 768px) {
    .changeemail-container h2,
    .change-password-container h2,
    .profile-container h2 {
        font-size: 35px;
    }
    
   
}

@media (max-width: 480px) {
    .changeemail-container h2,
    .change-password-container h2,
    .profile-container h2 {
        font-size: 30px;
    }
    
}

@media (max-width: 320px) {
    .changeemail-container h2,
    .change-password-container h2,
    .profile-container h2 {
        font-size: 28px;
    }
    
}

/* ============================= */
/*        Messages               */
/* ============================= */

/* Success, Error, and General Messages */
.success {
    color: green;
    text-align: center;
    margin-bottom: 20px;
}

.error,
.message {
    text-align: center;
    margin-bottom: 20px;
}

.error {
    color: red;
}

.message {
    color: green;
}

/* ============================= */
/*        Form Groups            */
/* ============================= */

/* Form Groups for Email, Password, and Profile */
.form-group-email,
.form-group-password,
.form-group {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center all form fields */
    position: relative; /* Relative positioning for button positioning (only for email/password) */
}
/* Label Styles */
.form-group-email label,
.form-group-password label,
.form-group label {
    font-size: 14px;
    margin-bottom: 15px;
    display: block;
    text-align: left;
    
}
.form-group-email {
    position: relative; 
}

/* Profile Picture */
.form-group img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
    cursor: pointer; /* Make it clear that it's clickable */
    display: block;
    margin: 0 auto; /* Center profile picture */
}
/* ============================= */
/*        Input Styles           */
/* ============================= */

/* Shared Input Styles for Email, Password, and Profile */


.form-group-email input[type="email"],
.form-group-email input[type="password"],
.form-group-password input[type="text"],
.form-group-password input[type="password"],
.form-group input[type="file"],
.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="date"] {
    width: 100%; 
    padding: 10px;
    border: 1px solid rgba(189, 189, 189, 0.75);
    border-radius: 30px;
    font-size: 14px;
    background: #333;
    color: #ccc;
    outline: none;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
}
.form-group-email input[type="email"]:focus,
.form-group-email input[type="password"]:focus,
.form-group-password input[type="password"]:focus,
.form-group input[type="file"]:focus,
.form-group input[type="text"]:focus,
.form-group input[type="email"]:focus,
.form-group input[type="date"]:focus {
    border: 2px solid rgba(255, 0, 0, 0.75); /* Red border on focus */
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25); /* Larger shadow effect */
}



.form-group-email input.password-visible {
    width: 100%; 
    padding: 10px;
    border: 1px solid rgba(189, 189, 189, 0.75);
    border-radius: 30px;
    font-size: 14px;
    background: #333;
    color: #ccc;
    outline: none;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;

}

.form-group-email input[type="email"]:focus,
.form-group-email input[type="password"]:focus,
.form-group-email input.password-visible:focus {
    border: 2px solid rgba(255, 0, 0, 0.75); /* Red border on focus */
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25); /* Larger shadow effect */
}


.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="date"] {
    width: 140%; /* Increased width for responsive design */
    padding: 15px; /* Increased padding */
    font-size: 16px; /* Increased font size */
    text-align: center; /* Center input text */
}

/* ============================= */
/*        Toggle Style           */
/* ============================= */

  
  .toggle-password {
    position: absolute;
    right: 0px; /* Adjust for spacing */
    cursor: pointer;
    top:108px;
    color: white; /* Adjust icon color */
    font-size: 16px; /* Adjust icon size */
  }
  .toggle-password-p {
    position: absolute;
    right: 0px; /* Adjust the positioning to match your design */
    cursor: pointer;
    top: 63%; /* Vertically center the icon in the input */
    transform: translateY(-50%); /* Centering trick */
    color:white; /* Icon color */
    font-size: 16px; /* Icon size */
}
.toggle-password-p.active,
.toggle-password.active {
    color: #ff0000;/* Change this color to your desired color when active */
  }

/* ============================= */
/*        Button Styles          */
/* ============================= */

/* Button Styles for Email and Password */
button.button-email,
button.button-password {
    font-weight: 600;
    width: 50%; /* Half the width of the input */
    border: none;
    border-radius: 15px;
    font-size: 12px;
    cursor: pointer;
    height: 32px;
    display: block;
    margin: 0 auto; /* Center the button horizontally */
    padding: 0 12px;
    background-color: #e50000;
    color: white;
    border: 1px solid #cc0000;
}
button.button-password{
    width:70%;
}

button.button-email:hover,
button.button-password:hover {
    box-shadow: none;
}

button.button-email:active,
button.button-password:active {
    transform: scale(0.96) translate(0px, 3.2px);
}

/* Button Styles for Profile */
button.button-profile {
    display: block;
    width: 200px; /* You can adjust the width if needed */
    margin: 10px auto; /* Equal margin and centered */
    padding: 10px;
    font-weight: 750;
    border: 1px solid white; /* Added white border */
    color: white;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
    background: linear-gradient(to right, #808080, #505050);
    transition: background-color 0.3s ease;
}


button.button-profile:hover {
    background: linear-gradient(to right, #606060, #303030);
}

button.button-profile:active {
    background: #404040;
}

/* Delete Account Button */
button.button-profile.delete-account-button {
    background: #e50000;
    border: 1px solid #cc0000;
}

button.button-profile.delete-account-button:hover {
    background: #cc0000;
    border: 1px solid #b30000;
}

/* ============================= */
/*        Responsive Design       */
/* ============================= */

/* Change Email & Password Containers */
@media (max-width: 1024px) {
    .changeemail-container,
    .change-password-container {
        max-width: 90%;
    }
    profile-container h2 {
        font-size: 40px;
    }

    .form-group input[type="text"],
    .form-group input[type="email"],
    .form-group input[type="date"] {
        width: 140%;
    }

    .form-group img {
        width: 90px;
        height: 90px;
    }
}

@media (max-width: 768px) {
    .changeemail-container,
    .change-password-container {
        max-width: 90%;
    }
    .profile-container h2 {
        font-size: 35px;
    }

    .form-group input[type="text"],
    .form-group input[type="email"],
    .form-group input[type="date"] {
        width: 120%;
    }

    .form-group img {
        width: 80px;
        height: 80px;
    }
}

@media (max-width: 480px) {
    .changeemail-container,
    .change-password-container {
        max-width: 90%;
        margin-left: 0; /* Corrected margin */
    }
    .profile-container h2 {
        font-size: 30px;
    }

    .form-group input[type="text"],
    .form-group input[type="email"],
    .form-group input[type="date"] {
        width: 130%;
    }

    .form-group img {
        width: 70px;
        height: 70px;
    }
}

@media (max-width: 320px) {
    .changeemail-container,
    .change-password-container {
        max-width: 90%;
    }

    .c-email {
        font-size: 14px;
    }
    .profile-container h2 {
        font-size: 28px;
    }

    .form-group input[type="text"],
    .form-group input[type="email"],
    .form-group input[type="date"] {
        width: 100%;
    }

    .form-group img {
        width: 60px;
        height: 60px;
    }
}






